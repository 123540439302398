import { Autocomplete, Box, Button, Grid, Tab, Tabs, TextField } from "@mui/material"
import React, { useState } from "react"
import ModalDialogPage from "src/components/modal-dialog"
import LayoutPage from "src/components/page-layout/layout-page"
import EmissaoTab from "./emissao_tab"
import FluxoEventosTab from "./fluxo_eventos_tab"
import DocumentosTab from "./documentos_tab"
import { DataGrid } from "@mui/x-data-grid"

const EmissaoPage = () => {
    const tabs = [
        { id: 0, description: "EMISSÃO" },
        { id: 1, description: "FLUXO DE EVENTOS" },
        { id: 2, description: "DOCUMENTOS" },
    ]

    const [selectedTab, setSelectedTab] = useState(0)
    const [openModalError, setOpenModalError] = useState(false)
    const [modalCompleteList, setModalCompleteList] = useState(false)
    const [reportErrorMessage, setReportErrorMessage] = useState("")

    const sendReportError = () => {
    }

    const openModalCompleteList = () => {
        setModalCompleteList(true)
    }

    const renderContent = () => {
        switch (selectedTab) {
            case 0: return <EmissaoTab />
            case 1: return <FluxoEventosTab />
            case 2: return <DocumentosTab />
            default: null
        }
    }

    return <LayoutPage title="Emissão">

        <Grid direaction={"row"} container alignItems="center" justifyContent="space-between" spacing={3}>
            <Grid item xs={6} md={6} sm={12}>
                <Grid container direction={"row"} spacing={3} alignItems="center">
                    <Grid item xs={4} md={4} sm={6}>
                        <Autocomplete
                            disablePortal
                            options={[]}
                            fullWidth
                            renderInput={(params) => <TextField {...params} label="CETIP ou ISIN" />}
                        />
                    </Grid>
                    <Grid item xs={6} md={6} sm={6}>
                        <Button color={"primary"} variant={"contained"} onClick={openModalCompleteList}>Consultar Lista Completa</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Button color={"primary"} variant={"contained"} onClick={() => { setReportErrorMessage(null), setOpenModalError(true) }}>Reportar Erro</Button>
            </Grid>
        </Grid>

        <br />

        <Grid container direction={"row"} alignItems={"center"}>
            <Grid item xs={12} sm={12} md={12} xl={12} sx={{ height: "100%" }}>
                <Box
                    sx={{
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column"
                    }}>
                    <Tabs value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)} aria-label="tabs">
                        {tabs.map((tab) => (
                            <Tab label={tab.description} key={tab.id} />
                        ))}
                    </Tabs>

                    <Box
                        sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                        }}>
                        <Grid
                            container
                            direction="row"
                            alignContent="flex-start"
                            justifyContent="flex-start"
                            spacing={0}
                            sx={{
                                padding: 2,
                                height: "100%", // altura de 100% da Box
                            }}>
                            <Grid item xs={12} sm={12} md={12} xl={12} sx={{ height: "100%" }}>
                                {renderContent()}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Grid>

        {/* Modais */}
        <ModalDialogPage open={openModalError}
            title="Reportar Erro"
            close={() => {
                setOpenModalError(false)
            }}>
            <>
                <Grid container direction="column">
                    <Grid item xs={12} md={12} xl={12}>
                        <TextField
                            label="Mensagem"
                            variant="outlined"
                            placeholder="Descreva o erro ou inconsistência nas informações que encontrou que nosso time de suporte irá rever!"
                            multiline
                            focused
                            minRows={10}
                            value={reportErrorMessage}
                            onChange={(event) => setReportErrorMessage(event.target.value)}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <br />
                <Grid container direction="column" alignContent={"flex-end"} justifyContent={"flex-end"}>
                    <Grid item>
                        <Button color={"primary"} variant={"contained"} onClick={sendReportError}>Reportar Erro</Button>
                    </Grid>
                </Grid>
            </>
        </ModalDialogPage>

        <ModalDialogPage open={modalCompleteList}
            title="Lista de CETIP/ISIN"
            close={() => {
                setModalCompleteList(false)
            }}>
            <>
                <Grid container direction="column">
                    <Grid item xs={12} md={12} xl={12}>
                        <DataGrid
                            autoHeight
                            columns={[
                                { field: 'cetip', headerName: 'CETIP', width: 200 },
                                { field: 'isin', headerName: 'ISIN', width: 200 },
                                { field: 'descricao', headerName: 'Tipo Devedor', width: 300 },
                                { field: 'descricao', headerName: 'Ativo', width: 300 },
                            ]}
                            rows={[]}
                        />
                    </Grid>
                </Grid>
            </>
        </ModalDialogPage>

    </LayoutPage>
}

export default EmissaoPage