import React, { useEffect } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { LocalizationProvider } from "@mui/x-date-pickers"
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { useState } from 'react'
import PropTypes from 'prop-types'

const DatePickerSelect = (props) => {
    const [date, setDate] = useState(dayjs(new Date()))

    useEffect(() => {
        if (props.value) {
            const newValue = dayjs(props.value)
            setDate(newValue)
        }
    }, [props.value])

    const changeDate = (value) => {
        setDate(value)
        props.onChange(value)
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
                <DatePicker
                    label={props.label ? props.label : "Data"}
                    value={date}
                    format="DD/MM/YYYY"
                    onChange={changeDate}
                />
            </DemoContainer>
        </LocalizationProvider>
    )
}

DatePickerSelect.propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.string
}

export default DatePickerSelect