import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material"
import React from "react"
import TableParametrosCalculoPreco from "./table/table_parametros_calculo_preco"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TableEventosFluxo from "./table/table_eventos_fluxo"


const FluxoEventosTab = () => {
    return <>
        <Grid
            container
            direction="row"
            alignContent="flex-start"
            justifyContent="flex-start"
            spacing={0}
            sx={{
                padding: 2,
                height: "100%", // altura de 100% da Box
            }}>
            <Grid item xs={12} sm={12} md={12} xl={12} sx={{ height: "100%" }}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography>Parâmetros para Cálculo de Preços</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableParametrosCalculoPreco data={[]} />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography>Eventos do Fluxo</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableEventosFluxo data={[]} />
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    </>
}

export default FluxoEventosTab