import React from "react"
import TableColumns from "src/components/table/table-columns"
import PropTypes from 'prop-types'
import dayjs from "dayjs"
import { DATE_FORMAT_SHOW } from "src/utils/constants"

const PriceCharacteristicsGuarantee = (props) => {
    const getDate = (value) => {
        if (props.data == null || props.data[value] == null) {
            return null
        }

        return dayjs(props.data[value]).format(DATE_FORMAT_SHOW)
    }

    const getValue = (value) => {
        if (props.data == null) {
            return null
        }

        return props.data[value]
    }
    
    const columns = [
        { keyA: "Data Documento", valueA: getDate('DataDocumento'), keyB: "Tipo Lastro", valueB: getValue('TipoLastro') },
        { keyA: "Repactuação Crédito", valueA: getValue('RepactuacaoCredito'), keyB: "Coobrigação", valueB: getValue('Coobrigacao') },
        { keyA: "Conta Vinculada", valueA: getValue('ContaVinculada'), keyB: "Alienação Fiduciária", valueB: getValue('AlienacaoFiduciaria') },
        { keyA: "Cessao Fiduciária", valueA: getValue('CessaoFiduciaria'), keyB: "Fiança Bancária", valueB: getValue('FiancaBancaria') },
        { keyA: "Hipoteca", valueA: getValue('Hipoteca'), keyB: "Penhor", valueB: getValue('Penhor') },
        { keyA: "Avalista", valueA: getValue('Avalista'), keyB: "Fiança", valueB: getValue('Fianca') },
        { keyA: "Fundo Obra", valueA: getValue('FundoObra'), keyB: "Fundo Reserva", valueB: getValue('FundoReserva') },
        { keyA: "Fundo Liquidez", valueA: getValue('FundoLiquidez'), keyB: "Seguro", valueB: getValue('Seguro') },
        { keyA: "Sobre Garantia", valueA: getValue('Sobregarantia'), keyB: "Covenants", valueB: getValue('Covenants') },
    ]

    return (
        <>
            <TableColumns columns={columns} title={`Garantias`} selectValueTitle={props.selectTitleList} onChangeSelect={props.onChangeTitle} changeValue={props.titleValue} />
        </>
    )
}

PriceCharacteristicsGuarantee.propTypes = {
    data: PropTypes.object,
    selectTitleList: PropTypes.array,
    onChangeTitle: PropTypes.func,
    titleValue: PropTypes.number
}


export default PriceCharacteristicsGuarantee