import { Grid } from "@mui/material"
import React from "react"
import TableDocumentos from "./table/table_documentos"

const DocumentosTab = () => {
    return <>
        <Grid
            container
            direction="row"
            alignContent="flex-start"
            justifyContent="flex-start"
            spacing={0}
            sx={{
                padding: 2,
                height: "100%", // altura de 100% da Box
            }}>
            <Grid item xs={12} sm={12} md={12} xl={12} sx={{ height: "100%" }}>
                <TableDocumentos />
            </Grid>
        </Grid>
    </>
}

export default DocumentosTab