import { INTEGRATION_MARKETDATA_PATH } from "src/utils/api-routes"
import { INTEGRATION_MARKETDATA_AIRFLOW_PATH } from "src/utils/api-routes"
import { INTEGRATION_PREVIAINFLACAO_PATH } from "src/utils/api-routes"
import { INTEGRATION_B3AtivosReg_PATH } from "src/utils/api-routes"
import { INTEGRATION_EstoqueRegistrado_PATH } from "src/utils/api-routes"
import { INTEGRATION_B3NegAnalitico_PATH } from "src/utils/api-routes"
import { INTEGRATION_B3NegConsolidado_PATH } from "src/utils/api-routes"
import { INTEGRATION_CVMFATOSRELEVANTES_PATH } from "src/utils/api-routes"
import { INTEGRATION_RISKFREE_PATH } from "src/utils/api-routes"
import { INTEGRATION_PREVIA_INFLACAO_ANBIMA_PATH } from "src/utils/api-routes"
import { INTEGRATION_BACEN_TAXAS_JUROS_PATH } from "src/utils/api-routes"
import { INTEGRATION_BACEN_CADASTRO_PATH } from "src/utils/api-routes"
import { INTEGRATION_TAXAS_RECEBIVEIS_MERCADO_PATH } from "src/utils/api-routes"
//import {INTEGRATION_RISKFREE_WORKFLOW_PATH} from "src/utils/api-routes"



import { ServiceRequest } from "src/utils/service-request"

export class IntegrationService{

    constructor(){   
        this.request= new ServiceRequest()
    }

    async startarRotinaMarkeData(data_inicial, data_final){
      //return this.request.post(`${ROUTINE_PATH}?data_referencia=${data_referencia}&qtde_dias=${qtde_dias}`,null)
    return this.request.post(INTEGRATION_MARKETDATA_PATH, {"data_inicial": data_inicial, "data_final": data_final } )

    }
    async startarRotinaMarkeDataAirflow(data_inicial, data_final){
      //return this.request.post(`${ROUTINE_PATH}?data_referencia=${data_referencia}&qtde_dias=${qtde_dias}`,null)
    return this.request.post(INTEGRATION_MARKETDATA_AIRFLOW_PATH, {"data_inicial": data_inicial, "data_final": data_final } )

    }

    async startarRotinaPreviaInflacao(indexadores, dataReferencia){
      //return this.request.post(`${ROUTINE_PATH}?data_referencia=${data_referencia}&qtde_dias=${qtde_dias}`,null)
    return this.request.post(INTEGRATION_PREVIAINFLACAO_PATH, {"indexadores": indexadores, "dataReferencia": dataReferencia} )

    }

    async startarRotinaB3AtivosReg(data_referencia){
    return this.request.post(INTEGRATION_B3AtivosReg_PATH, {"data_referencia": data_referencia} )
    }

    async startarRotinaEstoqueRegistrado(data_referencia){
    return this.request.post(INTEGRATION_EstoqueRegistrado_PATH, {"data_referencia": data_referencia} )

    }

    async startarRotinaB3NegAnalitico(data_referencia){
    return this.request.post(INTEGRATION_B3NegAnalitico_PATH, {"data_referencia": data_referencia} )
  
    }

    async startarRotinaB3NegConsolidado(data_referencia){
    return this.request.post(INTEGRATION_B3NegConsolidado_PATH, {"data_referencia": data_referencia} )
      
    }

    async startarCVMFatosRelevantes(data_referencia){
      return this.request.post(INTEGRATION_CVMFATOSRELEVANTES_PATH, {"data_referencia": data_referencia} )
      }

    async startarRiskFree(data_referencia){
      return this.request.post(INTEGRATION_RISKFREE_PATH, {"data_referencia": data_referencia} )
      }

    async startarPreviaInflacaoAnbima(data_referencia){
      return this.request.post(INTEGRATION_PREVIA_INFLACAO_ANBIMA_PATH, {"data_referencia": data_referencia} )
      }
   
    async startarBacenTaxasJuros(data_referencia){
        return this.request.post(INTEGRATION_BACEN_TAXAS_JUROS_PATH, {"data_referencia": data_referencia} )
        }
    
    async startarBacenCadastro(data_referencia) {
        return this.request.post(INTEGRATION_BACEN_CADASTRO_PATH, { "data_referencia": data_referencia })
    } 

    async startarTaxasRecebiveisMercado(data_referencia){
        return this.request.post(INTEGRATION_TAXAS_RECEBIVEIS_MERCADO_PATH, {"data_referencia": data_referencia} )
        }

    /* async startarRiskFreeWorkflow(data_referencia){
          return this.request.post(INTEGRATION_RISKFREE_WORKFLOW_PATH, {"data_referencia": data_referencia} )
          }
   */

}
