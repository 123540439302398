import { saveAs } from 'file-saver'
import html2canvas from 'html2canvas'


export class ExportFile {

    toCSV(data, fileName, separated) {
        let valueToSeparatedValues = ","
    
        if (separated !== null) {
            valueToSeparatedValues = separated
        }
    
        const header = Object.keys(data[0])
        let csv = header.join(valueToSeparatedValues) + '\n'
    
        data.forEach(objeto => {
            const linha = header.map(chave => objeto[chave])
            csv += linha.join(valueToSeparatedValues) + '\n'
        })
    
        const blob = new Blob(["\ufeff" + csv], { type: 'text/csv;charset=utf-8' })
        saveAs(blob, `${fileName}.csv`)
    }

    toImage(element, fileName) {
        html2canvas(element).then((canvas) => {
            // Converta o canvas para uma imagem base64
            const image = canvas.toDataURL()

            // Crie um link de download
            const link = document.createElement('a')
            link.href = image
            link.download = `${fileName}.png`

            // Dispare o evento de clique no link para iniciar o download
            link.click()
        })
    }

    downloadPdf(path, file) {
        const link = document.createElement('a')
        link.href = `${path}/${file}`
        link.download = file
        
        document.body.appendChild(link)
        
        link.click()
        document.body.removeChild(link)
    }
}

