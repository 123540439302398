import { Box, Button, Grid, Paper, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import LayoutPage from "src/components/page-layout/layout-page"
import SelectValue from "src/components/select-value"
import PriceService from "src/services/price-service"
import { DATE_FORMAT, DATE_FORMAT_SHOW } from "src/utils/constants"
import { localeBRDataGridText } from "src/utils/data-grid-local-text"
import AlertMessage from "src/components/alert-message"
import { formatDecimalCase } from "src/utils/format-decimal"

const PriceUpdateSpreadsPage = () => {
    const [loading, setLoading] = useState(false)
    const [dateList, setDateList] = useState([])
    const [selectedDate, setSelectedDate] = useState("")
    const [spreadList, setSpreadList] = useState([])
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: ""
    })
    const priceService = new PriceService()

    useEffect(() => {
        loadDates()
    }, [])

    const loadDates = () => {
        setLoading(true)
        priceService.getLastDatesOcurrence().then((response) => {
            setLoading(false)
            const { body } = response
            const { data } = body

            const dateList = data.map((data) => dayjs(data))
            const sortedList = dateList.sort((a, b) => b - a)
            const options = sortedList.map((item) => { return { value: item, description: item.format(DATE_FORMAT_SHOW) } })

            setDateList(options)
            changeDate(sortedList[0])
        })
    }

    const formatDate = (params) => {
        const parsedData = dayjs(params.value)
        if (parsedData.isValid()) {
            return parsedData.format(DATE_FORMAT_SHOW)
        } else {
            return ''
        }
    }

    const changeDate = (value) => {
        const data = value.format(DATE_FORMAT)
        setSpreadList({})
        setSelectedDate(value)

        setLoading(true)
        priceService.getUpdateSpreads(data).then((response) => {
            const { body } = response
            const { data } = body
            setLoading(false)

            const rows = data.map((obj, index) => ({ ...obj, id: index }))
            setSpreadList(rows)
        }).catch(() => {
            openAlert('Tivemos um problema ao carregar os dados, tente novamente', 'error')
            setLoading(false)
        })
    }

    const exportCSV = () => {
        setLoading(true)
        priceService.exportUpdateSpreads(selectedDate.format(DATE_FORMAT)).then(() => {
            setLoading(false)
        }).catch(() => {
            openAlert('Tivemos um problema ao carregar os dados, tente novamente', 'error')
            setLoading(false)
        })
    }

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    const renderTextColumn = (params) =>
        <Box sx={{ whiteSpace: 'pre-line', height: 'auto' }}>
            <Typography variant="p">{params.value}</Typography>
        </Box>

    const columns = [
        {
            field: '',
            headerName: 'Data Referência',
            flex: true,
            minWidth: 150,
            maxWidth: 150,
            renderCell: () => selectedDate.format(DATE_FORMAT_SHOW)
        },
        {
            field: 'CETIP',
            headerName: 'CETIP',
            flex: true,
            minWidth: 150,
            maxWidth: 150,
        },
        {
            field: 'TipoAtivo',
            headerName: 'Tipo',
            flex: 1,
            minWidth: 120,
            maxWidth: 150,
        },
        {
            field: 'Devedor',
            headerName: 'Devedor',
            flex: 1,
            minWidth: 200,
            maxWidth: 200,
            renderCell: renderTextColumn
        },
        {
            field: 'Setor',
            headerName: 'Setor',
            flex: 1,
            minWidth: 200,
            maxWidth: 300,
            renderCell: renderTextColumn
        },
        {
            field: 'SubSetor',
            headerName: 'SubSetor',
            flex: 1,
            minWidth: 100,
            maxWidth: 150,
            renderCell: renderTextColumn
        },
        {
            field: 'ValidadeRating',
            headerName: 'Validade Rating',
            flex: 1,
            minWidth: 200,
            maxWidth: 200,
            renderCell: formatDate
        },
        {
            field: 'SpreadAtual',
            headerName: 'Spread Atual',
            flex: 1,
            minWidth: 150,
            maxWidth: 150,
            renderCell: (params) => formatDecimalCase(params.value, 2)
        },
        {
            field: 'SpreadAnterior',
            headerName: 'Spread Anterior',
            flex: 1,
            minWidth: 150,
            maxWidth: 150,
            renderCell: (params) => formatDecimalCase(params.value, 2)
        },
        {
            field: 'DeltaSpread',
            headerName: 'Delta Spread',
            flex: 1,
            minWidth: 150,
            maxWidth: 150,
        },
        {
            field: 'DeltaPUMTM',
            headerName: 'Delta PUMTM',
            flex: 1,
            minWidth: 150,
            maxWidth: 150,
        },
        {
            field: 'DeltaScoreRating',
            headerName: 'Delta Score Rating',
            flex: 1,
            minWidth: 150,
            maxWidth: 150,
        },
        {
            field: 'AnaliseCreditoEmissao',
            headerName: 'Análise Crédito Emissão',
            flex: 1,
            sortable: false,
            minWidth: 300,
            maxWidth: 600,
            renderCell: renderTextColumn
        },
        {
            field: 'AnaliseCreditoDevedor',
            headerName: 'Análise Crédito Devedor',
            flex: 1,
            sortable: false,
            minWidth: 300,
            maxWidth: 600,
            renderCell: renderTextColumn
        },
        {
            field: 'AnaliseSpreadsIndicativos',
            headerName: 'Análise Spreads Indicativos',
            flex: 1,
            sortable: false,
            minWidth: 300,
            maxWidth: 600,
            renderCell: renderTextColumn
        },
    ]

    return <LayoutPage loading={loading} title="Atualização de Spreads">
        <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />
        <Grid container
            direction="row"
            justifyContent="flex-end"
            alignItems="center" spacing={3}>
            <Grid item xl={2} md={6} xs={6}>
                <SelectValue
                    label={"Data Referência"}
                    value={selectedDate}
                    id="select-app"
                    changeValue={changeDate}
                    options={dateList} />
            </Grid>
            <Grid item>
                <Button variant="contained" onClick={exportCSV}>
                    Exportar para CSV
                </Button>
            </Grid>
        </Grid>
        <br />
        <Grid container direction="row" justifyContent="center" spacing={2}>
            <Grid item xl={12} md={12} xs={12}>
                <Paper>
                    <DataGrid
                        getRowHeight={() => 'auto'}
                        rows={spreadList}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 25,
                                },
                            },
                        }}
                        pageSizeOptions={[25, 50, 100]}
                        disableRowSelectionOnClick
                        sortingOrder={['asc', 'desc']}
                        localeText={localeBRDataGridText}
                    />
                </Paper>

            </Grid>
        </Grid>
    </LayoutPage>
}

export default PriceUpdateSpreadsPage