import { PRICE_LAST_DATE_PATH, PRICE_PATH, PRICE_PU_PATH, PRICE_TABLE_LAST_DATE_PATH, PRICE_UPDATE_SPREADS_PATH } from "src/utils/api-routes"
import { DATE_FORMAT } from "src/utils/constants"
import { ExportFile } from "src/utils/export-file"
import { formatDate } from "src/utils/format-date"
import { formatDecimalCaseReplaceComma, formatDecimalCaseWithComma } from "src/utils/format-decimal"
import { ServiceRequest } from "src/utils/service-request"

class PriceService {
    constructor() {
        this.request = new ServiceRequest()
        this.exportFile = new ExportFile()
    }

    async getPrices(startDate, endDate) {
        return await this.request.get(`${PRICE_PATH}?data_inicial=${startDate}&data_final=${endDate}`)
    }

    async getPricesPU() {
        return await this.request.get(`${PRICE_PU_PATH}`)
    }

    async loadLastDate() {
        return await this.request.get(`${PRICE_TABLE_LAST_DATE_PATH}`)
    }

    async exportDataToCsv(startDate, endDate) {
        this.getPrices(startDate, endDate, 5000, 0).then((response) => {
            const { body } = response
            const { data } = body

            const prices = data.map((item) => {
                const {
                    CETIP,
                    DataReferencia,
                    TaxaMTM,
                    PU_MTM,
                    DurationMTM,
                    DurationAno,
                    PUPAR,
                    PercentualPUPAR,
                    PU_Inf,
                    PU_Sup,
                    tipo_ativo,
                    devedor,
                    indicador,
                    taxa_accrual
                } = item

                return {
                    "DataReferencia": DataReferencia,
                    "CETIP": CETIP,
                    "Tipo": tipo_ativo,
                    "Devedor": devedor,
                    "Indicador": indicador,
                    "TaxaACCRUAL": formatDecimalCaseReplaceComma(taxa_accrual),
                    "TaxaMTM": formatDecimalCaseReplaceComma(TaxaMTM),
                    "PU_MIN": formatDecimalCaseReplaceComma(PU_Inf),
                    "PU_MTM": formatDecimalCaseReplaceComma(PU_MTM),
                    "PU_MAX": formatDecimalCaseReplaceComma(PU_Sup),
                    "PUPAR": formatDecimalCaseReplaceComma(PUPAR),
                    "%PUPAR": formatDecimalCaseReplaceComma(PercentualPUPAR),
                    "DurationDU": formatDecimalCaseReplaceComma(DurationMTM),
                    "DurationAno": formatDecimalCaseReplaceComma(DurationAno)
                }
            })

            this.exportFile.toCSV(prices, `prices-${startDate}-${endDate}`, ";")
        })
    }

    async generatePriceData(startDate, endDate, cetipList) {
        let body = {
            "cetips": cetipList,
        }
        if (startDate) {
            body = { ...body, "data_inicial": startDate.format(DATE_FORMAT) }
        }

        if (endDate) {
            body = { ...body, "data_final": endDate.format(DATE_FORMAT) }
        }

        return await this.request.post(`${PRICE_PU_PATH}`, body)
    }

    generatePUChart(body) {
        const dataReferenciaArray = body.map(objeto => formatDate(objeto.DataReferencia))

        const puMTM = this.tranformData(body, "PU_MTM")

        return {
            PU_MTM: [{
                grupo: "PU MTM",
                items: puMTM.map(({ CETIP, values }) => ({
                    item: CETIP,
                    values
                }))
            }],
            dateList: [...dataReferenciaArray]
        }
    }

    generateTIRChart(body) {
        const dataReferenciaSet = new Set(body.map(objeto => formatDate(objeto.DataReferencia)))
        const taxaTIR = this.tranformData(body, "TaxaTIR")
        const taxaMTM = this.tranformData(body, "TaxaMTM")

        return {
            TAXA_TIR: [{
                grupo: "Taxa TIR",
                items: taxaTIR.map((fee) => {
                    return {
                        item: fee.CETIP,
                        values: fee.values
                    }
                })
            }],
            TAXA_MTM: [{
                grupo: "Taxa MTM",
                items: taxaMTM.map((fee) => {
                    return {
                        item: fee.CETIP,
                        values: fee.values
                    }
                })
            }],
            dateList: [...dataReferenciaSet]
        }
    }

    generatePUPARChart(body) {
        const cetipList = new Set(body.map(objeto => objeto.CETIP))
        let chartData = []

        cetipList.forEach(objeto => {

            const fromCetip = body.filter((value) => value.CETIP === objeto)

            const dataReferencia = fromCetip.map(item => formatDate(item.DataReferencia))
            const PU_MTM = fromCetip.map(item => parseFloat(item.PU_MTM).toFixed(3))
            const PUPAR = fromCetip.map(item => parseFloat(item.PUPAR).toFixed(3))
            const PERCENTUAL_PUPAR = fromCetip.map(item => parseFloat(item.PercentualPUPAR).toFixed(3))
            const data = {
                grupo: objeto,
                items: [
                    {
                        item: "PU_MTM",
                        id: null,
                        values: [...PU_MTM]
                    },
                    {
                        item: "PUPAR",
                        id: null,
                        values: [...PUPAR]
                    },
                    {
                        item: "%PUPAR",
                        id: "second-axis",
                        values: [...PERCENTUAL_PUPAR]
                    },
                ],
                dateList: [...dataReferencia]
            }

            chartData.push(data)
        })
        return chartData
    }

    tranformData(body, key) {
        const dadosAgrupados = {}

        body.forEach(dado => {
            const cetip = dado.CETIP
            const value = parseFloat(dado[key]).toFixed(5)

            dadosAgrupados[cetip] = dadosAgrupados[cetip] || { CETIP: cetip, values: [] }

            dadosAgrupados[cetip].values.push({ x: formatDate(dado.DataReferencia), y: value })
        })

        const gruposArray = Object.values(dadosAgrupados)

        return gruposArray
    }

    getLastDatesOcurrence(limit = 6) {
        return this.request.get(`${PRICE_LAST_DATE_PATH}?limit=${limit}`)
    }

    getUpdateSpreads(date) {
        return this.request.get(`${PRICE_UPDATE_SPREADS_PATH}?date=${date}`)
    }

    exportUpdateSpreads(date) {
        return this.getUpdateSpreads(date).then((response) => {
            const { body } = response
            const { data } = body

            const csvData = data.map((item) => {
                const {
                    CETIP,
                    TipoAtivo,
                    Devedor,
                    Setor,
                    SubSetor,
                    ValidadeRating,
                    AnaliseCreditoEmissao,
                    AnaliseCreditoDevedor,
                    SpreadAtual,
                    SpreadAnterior,
                    DeltaSpread,
                    AnaliseSpreadsIndicativos,
                    DeltaScoreRating, 
                    DeltaPUMTM
                } = item

                return {
                    "DataReferencia": date,
                    "CETIP": CETIP,
                    "TipoAtivo": TipoAtivo,
                    "Devedor": Devedor,
                    "Setor": Setor,
                    "SubSetor": SubSetor,
                    "ValidadeRating": ValidadeRating,
                    "SpreadAtual": formatDecimalCaseWithComma(SpreadAtual, 8),
                    "SpreadAnterior": formatDecimalCaseWithComma(SpreadAnterior, 8),
                    "DeltaSpread": formatDecimalCaseWithComma(DeltaSpread, 8),
                    "DeltaScoreRating": parseInt(DeltaScoreRating),
                    "DeltaPUMTM": formatDecimalCaseWithComma(DeltaPUMTM, 8),
                    "AnaliseCreditoEmissao": AnaliseCreditoEmissao,
                    "AnaliseCreditoDevedor": AnaliseCreditoDevedor,
                    "AnaliseSpreadsIndicativos": AnaliseSpreadsIndicativos
                }
            })

            this.exportFile.toCSV(csvData, `atualizacao-spreads-${date}`, ";")
        }).catch((err) => {
            console.log(err)
            return err
        })
    }
}

export default PriceService