import React from "react"
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material"
import PriceCharacteristicsEmissao from "../prices/characteristcs/table-characteristics/price-characteristics-emissao"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'


const EmissaoTab = () => {
    return <>
        <Grid
            container
            direction="row"
            alignContent="flex-start"
            justifyContent="flex-start"
            spacing={0}
            sx={{
                padding: 2,
                height: "100%", // altura de 100% da Box
            }}>
            <Grid item xs={12} sm={12} md={12} xl={12} sx={{ height: "100%" }}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography>Características e Garantias</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PriceCharacteristicsEmissao data={[]} />
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    </>
}

export default EmissaoTab