import { Box, Button, Grid, Paper } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import AlertMessage from "src/components/alert-message"
import LineChartModalComponent from "src/components/chart/line-chart-modal"
import DatePickerSelect from "src/components/date/date-picker-select"
import LineCheckbox from "src/components/line-checkbox"
import LayoutPage from "src/components/page-layout/layout-page"
import CurveService from "src/services/curve-service"
import { DATE_FORMAT, DATE_FORMAT_SHOW } from "src/utils/constants"
import { localeBRDataGridText } from "src/utils/data-grid-local-text"

const CurvesSubsetorialPage = () => {
    const curveService = new CurveService()
    const [curveList, setCurveList] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [dateSelected, setDateSelected] = useState(null)
    const [dateSelectedShow, setDateSelectedShow] = useState(null)
    const [tableRating, setTableRating] = useState([])
    const [ratingsSelected, setRatingsSelected] = useState([])
    const [openChart, setOpenChart] = useState(false)
    const [csvData, setCsvData] = useState([])
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: "info"
    })
    const [chartConfig, serChartConfig] = useState({
        title: "",
        xTitle: "",
        yTitle: "",
        xValue: [],
        values: []
    })
    const [chartVolConfig, setChartVolConfig] = useState({
        title: "",
        xTitle: "",
        yTitle: "",
        xValue: [],
        values: []
    })

    useEffect(() => {
        const date = dayjs()
        const dateFormatted = date.format(DATE_FORMAT)
        setDateSelectedShow(date.format(DATE_FORMAT_SHOW))
        loadCurves(dateFormatted)
    }, [])

    const onChangeDatePickerDate = (value) => {
        setDateSelectedShow(value.format(DATE_FORMAT_SHOW))
        setDateSelected(value.format(DATE_FORMAT))
    }

    const onSelectRow = (rows) => {
        let selected = []
        rows.map((item) => {
            selected.push(curveList[item])
        })
        setSelectedRows(selected)
    }

    const validateBody = () => {
        let valid = true

        if (ratingsSelected.length === 0) {
            openAlert("Selecione ao menos um rating", "warning")
            valid = false
        }

        if (selectedRows.length === 0) {
            openAlert("Selecione ao menos um grupo", "warning")
            valid = false
        }

        if (dateSelected === null) {
            openAlert("Selecione uma data válida", "warning")
            valid = false
        }

        return valid
    }

    const loadCurves = (date) => {
        setDateSelected(date)
        setLoading(true)
        curveService.getCurvesSubsetorial(date)
            .then((response) => {
                const { body } = response
                const { grupos, ratings, data_referencia } = body
    
                // Lista dos grupos indesejados = serão removidos 
                const gruposIndesejadosSet = new Set([
                    'CDB/RDB/LC S1 DISTRIBUIDOR',
                    'CDB/RDB/LC S2 DISTRIBUIDOR',
                    'CDB/RDB/LC S3 DISTRIBUIDOR',
                    'CDB/RDB/LC S4 DISTRIBUIDOR',
                    'CDB/RDB/LC S5 DISTRIBUIDOR',
                    'LF/CDB/LC/RDB/DPGE S1',
                    'LF/CDB/LC/RDB/DPGE S2',
                    'LF/CDB/LC/RDB/DPGE S3',
                    'LF/CDB/LC/RDB/DPGE S4',
                    'LF/CDB/LC/RDB/DPGE S5',
                    'LCI/LCA S1 DISTRIBUIDOR',
                    'LCI/LCA S2 DISTRIBUIDOR',
                    'LCI/LCA S3 DISTRIBUIDOR',
                    'LCI/LCA S4 DISTRIBUIDOR',
                    'LCI/LCA S5 DISTRIBUIDOR',
                    'LFS/LFSN S1',
                    'LFS/LFSN S2',
                    'LFS/LFSN S3',
                    'LFS/LFSN S4',
                    'LFSC S1',
                    'LFSC S2',
                    'LFSC S3',
                    'LFSC S4'
                ])
    
                // Filtra os grupos removendo a lista indesejada
                const filteredGrupos = grupos.filter(grupo => !gruposIndesejadosSet.has(grupo))
    
                const rows = filteredGrupos.map((grupo, index) => ({ id: index, grupo: grupo }))
                const rowsRating = ratings
                    .map((rating) => rating?.Rating)
                    .filter(rating => rating && rating !== "Risk Free") // Remove "Risk Free" do checkbox 
    
                setCurveList(rows)
                setTableRating(rowsRating)
                setDateSelected(data_referencia)
                setDateSelectedShow(dayjs(data_referencia).format(DATE_FORMAT_SHOW))
                setRatingsSelected(rowsRating)
                setLoading(false)
            })
            .catch((error) => {
                console.error("Erro ao carregar curvas:", error)  // Log do erro
                setLoading(false)
            })
    }
    
    const selectRating = (items) => {
        setRatingsSelected(items)
    }

    const loadChartData = async () => {
        if (!validateBody()) {
            return
        }

        setLoading(true)
        const groups = selectedRows.map((grupo) => grupo.grupo)
        curveService.generateCurveChart(groups, ratingsSelected, dateSelected, "Subsetorial", 3).then((response) => {
            const { taxa, volatilidade } = response
            console.log(response)
            serChartConfig({
                title: "Curvas Subsetoriais",
                xTitle: taxa.curva.NomeCurva,
                yTitle: "Taxas %",
                xValue: taxa.curva.values,
                values: taxa.grupos
            })

            setChartVolConfig({
                title: "Curvas Subsetoriais",
                xTitle: volatilidade.curva.NomeCurva,
                yTitle: "Taxas %",
                xValue: volatilidade.curva.values,
                values: volatilidade.grupos
            })

            setOpenChart(true)
            setLoading(false)
            setCsvData(taxa.rawData)
        }).catch(() => {
            setLoading(false)
            openAlert("Nenhum dado a ser exibido com os parâmetros informados", "warning")
        })

    }

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    const columns = [
        {
            field: 'grupo',
            headerName: 'Descrição',
            flex: true,
            minWidth: 250,
            disableColumnMenu: true
        }
    ]

    return (
        <LayoutPage
            loading={loading}
            title="Curvas Subsetoriais">
            <div>
                <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />
                <Grid container direction="row" alignItems={"center"} justifyContent="flex-end" spacing={3}>
                    <Grid item>
                        <DatePickerSelect
                            label="Data de Exibição"
                            onChange={onChangeDatePickerDate}
                            value={dateSelected} />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={loadChartData}>
                            Gerar Gráfico
                        </Button>
                    </Grid>
                </Grid>
                <Box marginBottom={6} />
                <Paper>
                    <LineCheckbox
                        items={tableRating}
                        callback={selectRating}
                        allChecked
                    />
                    <br />
                </Paper>
                <Grid container direction="row" justifyContent="center" spacing={2}>
                    <Grid item xl={12} md={12} xs={12}>
                        <Paper>
                            <DataGrid
                                autoHeight
                                checkboxSelection
                                getRowHeight={() => 'auto'}
                                rows={curveList}
                                columns={columns}
                                onRowSelectionModelChange={onSelectRow}
                                sortingOrder={['asc', 'desc']}
                                localeText={localeBRDataGridText}
                            />
                        </Paper>
                    </Grid>
                </Grid>

                <LineChartModalComponent
                    open={openChart}
                    onClose={() => setOpenChart(false)}
                    title={`Curvas Subsetoriais`}
                    selectedDate={dateSelectedShow}
                    chartOneData={chartConfig}
                    chartTwoData={chartVolConfig}
                    csvData={csvData}
                    chartOneSelectTitle={"Taxa"}
                    chartTwoSelectTitle={"Volatilidade"}
                />

            </div>
        </LayoutPage>
    )
}

export default CurvesSubsetorialPage

