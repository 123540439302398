import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import dayjs from "dayjs"
import React from "react"
import { DATE_FORMAT_SHOW } from "src/utils/constants"
import theme from "src/utils/theme"
import PropTypes from 'prop-types'
import { formatStringToFloatDecimalCase } from "src/utils/format-decimal"


export const puparHeadItems = [
    'Fluxo',
    'Data Evento',
    'Data Liquidação',
    'DU',
    '%Amortização Ordinária',
    '%Amortização Extraordinária',
    '%Correção Indicador',
    '%Juros',
    '%Juros Incorporação',
    'Valor Nominal',
    'VNA',
    'Amortização Ordinária',
    'Amortização Extraordinária',
    'Juros',
    'Juros Incorporação',
    'Prêmio',
    'Pagamento',
    '%Taxa VF',
    'Valor Futuro',
    '%Taxa VP',
    'PUPAR',
]

const PriceMemoryPUPAR = (props) => {
    const tableHeadItem = (title, key) => <TableCell key={key} align="center" sx={{ color: "#fff", fontWeight: 'bold', border: '1px solid black', backgroundColor: theme.palette.primary.main, whiteSpace: 'pre-wrap' }}>{title}</TableCell>
    const tableItem = (value) => <TableCell align="center" sx={{ border: '1px solid black' }}>{value}</TableCell>

    const getDate = (value) => {
        if (value != null) {
            return dayjs(value).format(DATE_FORMAT_SHOW)
        }
    }

    return (
        <Paper>
            <Typography variant="h6" sx={{ backgroundColor: theme.palette.primary.main, color: '#fff', p: 1, textAlign: 'center' }}>
                Memória de Cálculo PUPAR
            </Typography>
            <TableContainer sx={{
                maxHeight: 400,
                '&::-webkit-scrollbar': {
                    width: '8px',
                    height: '10px',
                },
            }}>
                <Table ref={props.tableRef} stickyHeader>
                    <TableHead sx={{ backgroundColor: theme.palette.primary.main }}>
                        <TableRow>
                            {puparHeadItems.map((item, index) => tableHeadItem(item, `headTable${index}`))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.map((item, index) => <TableRow key={`tabPUMTM${index}`}>
                            {tableItem(item.Fluxo)}
                            {tableItem(getDate(item.DataEvento))}
                            {tableItem(getDate(item.DataLiquidacao))}
                            {tableItem(item.DU)}
                            {tableItem(formatStringToFloatDecimalCase(item.AmortizacaoOrdinariaPercentual))}
                            {tableItem(formatStringToFloatDecimalCase(item.AmortizacaoExtraordinariaPercentual))}
                            {tableItem(item.CorrecaoIndicadorPercentual)}
                            {tableItem(item.JurosPercentual)}
                            {tableItem(item.IncorporacaoJurosPercentual)}
                            {tableItem(formatStringToFloatDecimalCase(item.ValorNominal))}
                            {tableItem(formatStringToFloatDecimalCase(item.VNA))}
                            {tableItem(formatStringToFloatDecimalCase(item.AmortizacaoOrdinaria))}
                            {tableItem(formatStringToFloatDecimalCase(item.AmortizacaoExtraordinaria))}
                            {tableItem(formatStringToFloatDecimalCase(item.Juros))}
                            {tableItem(item.IncorporacaoJuros)}
                            {tableItem(formatStringToFloatDecimalCase(item.Premio))}
                            {tableItem(formatStringToFloatDecimalCase(item.Pagamento))}
                            {tableItem(formatStringToFloatDecimalCase(item.TaxaVF))}
                            {tableItem(formatStringToFloatDecimalCase(item.ValorFuturo))}
                            {tableItem(formatStringToFloatDecimalCase(item.TaxaVP))}
                            {tableItem(formatStringToFloatDecimalCase(item.PUPAR))}
                        </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}


PriceMemoryPUPAR.propTypes = {
    data: PropTypes.object,
    tableRef: PropTypes.any
}


export default PriceMemoryPUPAR