import { CALC_CURVES_TYPE_PATH, CALC_CURVES_TYPE_TAX_PATH, CURVES_PATH } from "src/utils/api-routes"
import { ExportFile } from "src/utils/export-file"
import { formatDecimalCase, formatStringToFloatDecimalCase } from "src/utils/format-decimal"
import { ServiceRequest } from "src/utils/service-request"

class CurveService {
    constructor() {
        this.request = new ServiceRequest()
        this.exportFile = new ExportFile()
    }

    async getCurves(date) {
        return await this.request.get(`${CURVES_PATH}?data_referencia=${date}`)
    }

    async exportDataToCsv(date, dateFormatted) {
        this.getCurves(date).then((response) => {
            const { body } = response
            const { data } = body

            const curves = data.map((item) => {
                const {
                    Descricao,
                    Rating,
                    DU,
                    TIR,
                    VolatilidadeTIR,
                    Spread,
                    VolatilidadeSpread,
                    DTS
                } = item

                return {
                    'Descricao': Descricao,
                    'Rating': Rating,
                    'DataReferencia': dateFormatted,
                    'DU': formatStringToFloatDecimalCase(DU),
                    'TIR': formatStringToFloatDecimalCase(TIR, 3, 3),
                    'VolatilidadeTIR': formatStringToFloatDecimalCase(VolatilidadeTIR, 4, 4),
                    'Spread': formatStringToFloatDecimalCase(Spread, 3, 3),
                    'VolatilidadeSpread': formatStringToFloatDecimalCase(VolatilidadeSpread, 4, 4),
                    'DTS': formatStringToFloatDecimalCase(DTS, 5, 5)
                }
            })

            this.exportFile.toCSV(curves, `curvas-${date}`, ";")
        }).catch((err) => {
            throw err
        })
    }

    generateChartCSVData(results) {
        const values = results.map((item) => {
            const {
                IDCurva,
                DU,
                TaxaCurvas,
                Descricao,
                Rating,
                Volatilidade
            } = item

            return {
                "Descricao": Descricao,
                "Rating": Rating,
                "IDCurva": IDCurva,
                "TaxaCurvas": formatStringToFloatDecimalCase(TaxaCurvas),
                "Volatilidade": formatStringToFloatDecimalCase(Volatilidade, 4, 4),
                "DU": formatDecimalCase(DU),
            }
        })
        return values
    }

    async getCurvesTir() {
        return await this.request.get(`${CALC_CURVES_TYPE_PATH}/tir`)
    }

    async getCurvesSetorial() {
        return await this.request.get(`${CALC_CURVES_TYPE_PATH}/setorial`)
    }

    async getCurvesSubsetorial() {
        return await this.request.get(`${CALC_CURVES_TYPE_PATH}/subsetorial`)
    }

    async getCurvesSpread() {
        return await this.request.get(`${CALC_CURVES_TYPE_PATH}/spreads`)
    }

    async getCurvesDTS() {
        return await this.request.get(`${CALC_CURVES_TYPE_TAX_PATH}/spread`)
    }

    async generateDTSChart(grupos, ratings, date) {
        const body = {
            grupos: grupos,
            ratings: ratings,
            data_referencia: date
        }

        const response = await this.request.post(`${CALC_CURVES_TYPE_TAX_PATH}/Spread`, body)
        response.body.result.forEach(item => {
            if (item.DTS) { 
                item.DTS = Math.round(item.DTS * 100) // multiplicar gráfico DTS por 100 
            }
        })
        return {
            taxa: this.tranformData("DTS", response, 2),
            volatilidade: []
        }
    }

    async generateCurveChart(grupos, ratings, date, type, decimal) {
        const body = {
            grupos: grupos,
            ratings: ratings,
            data_referencia: date
        }
        let decimalDefault = decimal ? decimal : 2
        const response = await this.request.post(`${CALC_CURVES_TYPE_PATH}/${type}`, body)
        return {
            rawData: response,
            taxa: this.tranformData("TaxaCurvas", response, decimalDefault), // somente setorial 3 casas decimais
            volatilidade: this.tranformData("Volatilidade", response, 4)
        }
    }

    tranformData(key, response, decimalCase) {
        const { body: responseBody } = response
        const { result } = responseBody

        const groups = [...new Set(result.map((item) => item.Descricao))]

        const data = groups.map((group) => {
            const curves = result.filter((value) => value.Descricao === group)
            const ratings = [...new Set(curves.map((item) => item.Rating))]

            const items = ratings.map((rating) => {
                const resultsFromRating = result.filter(
                    (result) => result.Descricao === group && result.Rating === rating
                )
                const gruposAndValues = resultsFromRating.map((item) => formatDecimalCase(item[key], decimalCase))

                return {
                    item: rating,
                    values: gruposAndValues,
                }
            })

            return {
                grupo: group,
                items: items
            }
        })

        const curva = {
            NomeCurva: "DU",
            values: [...new Set(result.map((item) => formatDecimalCase(item.DU, 0)))]
        }
        curva.values.sort((a, b) => a - b)

        return {
            curva,
            grupos: data,
            rawData: this.generateChartCSVData(result)
        }
    }

    // getCurvesTir() {
    //     return {
    //         body: {
    //             results: [
    //                 {
    //                     grupo: "CDB/RDB/LC DISTRIBUIDOR DI",
    //                     ratings: ["A", "AA", "AAA", "Risk Free"]
    //                 },
    //                 {
    //                     grupo: "CDB/RDB/LC DISTRIBUIDOR IPCA",
    //                     ratings: ["A", "AA", "AAA"]
    //                 },
    //                 {
    //                     grupo: "CORPORATE DI",
    //                     ratings: ["A", "AA", "AAA"]
    //                 },
    //                 {
    //                     grupo: "CORPORATE DOLAR",
    //                     ratings: ["A", "AA", "AAA"]
    //                 },
    //                 {
    //                     grupo: "CORPORATE IGPM",
    //                     ratings: ["A", "AA", "AAA"]
    //                 }
    //             ]
    //         }
    //     }
    // }

    getCurvesTirChart() {

        return {
            body: {
                "curva": {
                    "NomeCurva": "DU",
                    "values": Array.from({ length: 10 }, () => Math.floor(Math.random() * 100))
                },
                "volatilidade": [
                    {
                        "grupo": "Volatilidade",
                        "items": [
                            {
                                "item": "AAA",
                                "values": Array.from({ length: 10 }, () => Math.floor(Math.random() * 100))
                            },
                            {
                                "item": "AA",
                                "values": Array.from({ length: 10 }, () => Math.floor(Math.random() * 100))
                            },
                            {
                                "item": "A",
                                "values": Array.from({ length: 10 }, () => Math.floor(Math.random() * 100))
                            },
                            {
                                "item": "Risk Free",
                                "values": Array.from({ length: 10 }, () => Math.floor(Math.random() * 100))
                            }
                        ]
                    },
                ],
                "grupos": [
                    {
                        "grupo": "CDB DI",
                        "items": [
                            {
                                "item": "AAA",
                                "values": Array.from({ length: 10 }, () => Math.floor(Math.random() * 100))
                            },
                            {
                                "item": "AA",
                                "values": Array.from({ length: 10 }, () => Math.floor(Math.random() * 100))
                            },
                            {
                                "item": "A",
                                "values": Array.from({ length: 10 }, () => Math.floor(Math.random() * 100))
                            },
                            {
                                "item": "Risk Free",
                                "values": Array.from({ length: 10 }, () => Math.floor(Math.random() * 100))
                            }
                        ]
                    },
                    {
                        "grupo": "CDB IPCA",
                        "items": [
                            {
                                "item": "AAA",
                                "values": Array.from({ length: 10 }, () => Math.floor(Math.random() * 100))
                            },
                            {
                                "item": "AA",
                                "values": Array.from({ length: 10 }, () => Math.floor(Math.random() * 100))
                            },
                            {
                                "item": "A",
                                "values": Array.from({ length: 10 }, () => Math.floor(Math.random() * 100))
                            },
                            {
                                "item": "Risk Free",
                                "values": Array.from({ length: 10 }, () => Math.floor(Math.random() * 100))
                            },
                            {
                                "item": "BBB",
                                "values": Array.from({ length: 10 }, () => Math.floor(Math.random() * 100))
                            }
                        ]
                    }
                ]
            }
        }

    }

}

export default CurveService