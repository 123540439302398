import { Button, FormControl, InputLabel, MenuItem, Select, Grid } from "@mui/material"
import React, { useState } from "react"
import AlertMessage from "src/components/alert-message"
import DatePickerSelect from "src/components/date/date-picker-select"
import DateTimePickerSelectDate from "src/components/date/date-time-picker-select-date"
import LayoutPage from "src/components/page-layout/layout-page"
import { DATE_FORMAT, DATE_FORMAT_ISO8601 } from "src/utils/constants"
import { IntegrationService } from "src/services/integration-service"
import dayjs from "dayjs"

const IntegrationPage = () => {
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: ""
    })
    const [dateSelected, setDateSelected] = useState(dayjs().set('hour', 19).set('minute', 15).set('second', 0).set('millisecond', 670))
    const [dateSelectedAiflow, setDateSelectedAirflow] = useState(dayjs().set('hour', 19).set('minute', 15).set('second', 0).set('millisecond', 670))
    const [dateSelectedDate, setDateSelectedDate] = useState(dayjs().format(DATE_FORMAT_ISO8601))
    const [dateSelectedFinal, setDateSelectedFinal] = useState(dayjs().set('hour', 19).set('minute', 15).set('second', 0).set('millisecond', 670))
    const [dateSelectedFinalAirflow, setDateSelectedFinalAirflow] = useState(dayjs().set('hour', 19).set('minute', 15).set('second', 0).set('millisecond', 670))
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState(null)

    const integrationService = new IntegrationService()

    const alterarValor = (event) => {
        setName(event.target.value)
    }

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }

    const inicialRotina = () => {
        console.log(name)
        if (name === null) {
            openAlert('Escolha uma rotina', 'warning')
            return
        }
        setLoading(true)
        const indexadores = ["IPCA", "IGPM"]
        switch (name) {
            case 'B3AtivosReg':
                integrationService.startarB3AtivosReg(dateSelectedDate).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado com sucesso!', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            case 'B3NegAnalitico':
                integrationService.startarRotinaB3NegAnalitico(dateSelectedDate).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado com sucesso!', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            case 'B3NegConsolidado':
                integrationService.startarRotinaB3NegConsolidado(dateSelectedDate).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado com sucesso!', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            case 'BacenCadastro':
                integrationService.startarBacenCadastro(dateSelectedDate).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado com sucesso!', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            case 'CVMFatosRelevantes':
                integrationService.startarCVMFatosRelevantes(dateSelectedDate).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado com sucesso!', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            case 'EstoqueRegistrado':
                integrationService.startarRotinaEstoqueRegistrado(dateSelectedDate).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado com sucesso!', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            case 'MarketData':
                integrationService.startarRotinaMarkeData(dateSelected, dateSelectedFinal).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            case 'MarketData_airflow':
                integrationService.startarRotinaMarkeDataAirflow(dateSelectedAiflow, dateSelectedFinalAirflow).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            case 'PreviaInflacao':
                integrationService.startarRotinaPreviaInflacao(indexadores, dateSelectedDate).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado com sucesso!', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            case 'PreviaInflacaoAnbima':
                integrationService.startarPreviaInflacaoAnbima(dateSelectedDate).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado com sucesso!', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            case 'RiskFree':
                integrationService.startarRiskFree(dateSelectedDate).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado com sucesso!', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            case 'TaxasRecebiveisMercado':
                integrationService.startarTaxasRecebiveisMercado(dateSelectedDate).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado com sucesso!', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break

            /* case 'RiskFreeWorkflow':
                integrationService.startarRiskFreeWorkflow(dateSelectedDate).then(() => {
                    setLoading(false)
                    openAlert('Processo iniciado com sucesso!', 'success')
                }).catch(() => {
                    setLoading(false)
                    openAlert('Erro no processo!', 'error')
                })
                break */
        }
    }

    const onChangeDatePickerDate = (value) => {
        setDateSelected(value)
    }

    const onChangeDatePickerDateFinal = (value) => {
        setDateSelectedFinal(value)
    }

    const onChangeDatePickerDateTime = (value) => {
        const formattedDate = value.format(DATE_FORMAT)
        setDateSelectedDate(formattedDate)
    }

    const onChangeDatePickerDate_airflow = (value) => {
        setDateSelectedAirflow(value)
    }

    const onChangeDatePickerDateFinal_aiflow = (value) => {
        setDateSelectedFinalAirflow(value)

        const dateInicial = dayjs(value).subtract(7, 'day')
        setDateSelectedAirflow(dateInicial)

        console.log("Data Inicial (7 dias antes):", dateInicial.format(DATE_FORMAT_ISO8601))
    }

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    return (
        <LayoutPage title="Rotinas" loading={loading} >
            <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />

            <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                <Grid item sm={12} md={6} xl={4} xs={12}>
                    Importação manual das rotinas!
                </Grid>
            </Grid>
            <br />
            <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                <Grid item xs={12} md={6} sm={12} xl={4}>
                    <Grid container direction={"column"} alignItems={"center"} justifyContent={"center"}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Rotinas</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={name}
                                label="Rotinas"
                                onChange={alterarValor}
                            >
                                <MenuItem value="">Escolha uma rotina</MenuItem>
                                <MenuItem value="B3AtivosReg">B3AtivosReg</MenuItem>
                                <MenuItem value="B3NegAnalitico">B3NegAnalitico</MenuItem>
                                <MenuItem value="B3NegConsolidado">B3NegConsolidado</MenuItem>
                                <MenuItem value="BacenCadastro">BacenCadastro</MenuItem>
                                <MenuItem value="CVMFatosRelevantes">CVMFatosRelevantes</MenuItem>
                                <MenuItem value="EstoqueRegistrado">EstoqueRegistrado</MenuItem>
                                <MenuItem value="MarketData">MarketData</MenuItem>
                                <MenuItem value="MarketData_airflow">MarketData_airflow</MenuItem>
                                <MenuItem value="PreviaInflacao">Previa de Inflação</MenuItem>
                                <MenuItem value="PreviaInflacaoAnbima">PreviaInflacaoAnbima</MenuItem>
                                <MenuItem value="RiskFree">RiskFree</MenuItem>
                                <MenuItem value="TaxasRecebiveisMercado">TaxasRecebiveisMercado</MenuItem>
                                {/* <MenuItem value="RiskFreeWorkflow">RiskFreeWorkflow</MenuItem> */}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                {name === 'MarketData' && (
                    <>
                        <Grid item sm={12} md={6} xl={4} xs={12}>
                            <DateTimePickerSelectDate
                                label="Data de Exibição"
                                onChange={onChangeDatePickerDate}
                                value={dateSelected}
                            />
                        </Grid>
                        <Grid item sm={12} md={6} xl={4} xs={12}>
                            <DateTimePickerSelectDate
                                label="Data de Exibição Final"
                                onChange={onChangeDatePickerDateFinal}
                                value={dateSelectedFinal}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            <br />
            <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                {name === 'MarketData_airflow' && (
                    <>
                        <Grid item sm={12} md={6} xl={4} xs={12}>
                            <DateTimePickerSelectDate
                                label="Data de Exibição"
                                onChange={onChangeDatePickerDate_airflow}
                                value={dateSelectedAiflow}
                            />
                        </Grid>
                        <Grid item sm={12} md={6} xl={4} xs={12}>
                            <DateTimePickerSelectDate
                                label="Data de Exibição Final"
                                onChange={onChangeDatePickerDateFinal_aiflow}
                                value={dateSelectedFinalAirflow}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            <br />
            <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                {(name === 'PreviaInflacao' || name === 'B3NegConsolidado' || name === 'B3AtivosReg' || name === 'EstoqueRegistrado' || name === 'B3NegAnalitico' || name === 'CVMFatosRelevantes' || name === 'RiskFree' || name === 'PreviaInflacaoAnbima' || name === 'BacenTaxasJuros' || name === 'TaxasRecebiveisMercado' || name === 'RiskFreeWorkflow') && (
                    <Grid item sm={12} md={6} xl={4} xs={12}>
                        <DatePickerSelect
                            label="Data de Exibição"
                            onChange={onChangeDatePickerDateTime}
                            value={dateSelectedDate}
                        />
                    </Grid>
                )}
            </Grid>

            <br />
            <br />
            <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                <Grid item sm={12} md={6} xl={4} xs={12}>
                    <Button variant="contained" onClick={inicialRotina}>Iniciar Rotina</Button>
                </Grid>
            </Grid>
        </LayoutPage>
    )
}
export default IntegrationPage
