import React, { useEffect, useState } from "react"
import { formatDateDataGrid } from "src/utils/format-date"
import PropTypes from 'prop-types'
import ParameterPaginationTable from "src/components/table/parameter-pagination-table"
import DatalakeService from "src/services/datalake-service"
import dayjs from "dayjs"
import { DATE_FORMAT } from "src/utils/constants"

const ParameterDatalakeSpreadsDevedorTable = (props) => {
    const dataLakeService = new DatalakeService()
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({ count: 0, next: null, previous: null })

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint).then((response) => {
            props.loading(false)

            if (response && response.body) {
                const { body } = response
                const { results, count, next, previous } = body
                const list = results.map((obj, index) => ({ ...obj, id: index }))
                setPagination({ count: count, next: next, previous: previous })
                setData(list)
            } else {
                console.error("Invalid response structure:", response)
            }
        }).catch((err) => {
            props.loading(false)
            console.error("Error loading data:", err)
        })
    }

    const createRow = async () => {
        const body = {
            "CPFCNPJ": 'N/A',
            "DataReferencia": dayjs().format(DATE_FORMAT),
            "GrupoEconomico": 'N/A',
            "ScoreRating": 0,
            "Rating": 'N/A',
            "QtdSecundario": 0,
            "QtdPrimario": 0,
            "QtdIndicativo": 0,
            "QtdTotal": 0,
            "QtdOutlier": 0,
            "IDCurvaSpread": 0,
            "IDCurvaSubsetor": 0,
            "DU": 0,
            "PremioDevedor": 0,
            "IndiceLiquidezDevedor": 0,
        }

        props.loading(true)
        return dataLakeService.createTableEndpoint(props.endpoint, body).then(() => {
            props.loading(false)
            loadData()
        }).catch((err) => {
            props.loading(false)
            console.error("Error creating row:", err)
        })
    }

    const updateRow = async (newRow) => {
        const { ID, CPFCNPJ, DataReferencia, GrupoEconomico, ScoreRating, Rating, QtdSecundario, QtdPrimario, QtdIndicativo, QtdTotal, QtdOutlier, IDCurvaSpread, IDCurvaSubsetor, DU, PremioDevedor, IndiceLiquidezDevedor } = newRow

        const body = {
            "CPFCNPJ": CPFCNPJ,
            "DataReferencia": DataReferencia,
            "GrupoEconomico": GrupoEconomico,
            "ScoreRating": ScoreRating,
            "Rating": Rating,
            "QtdSecundario": QtdSecundario,
            "QtdPrimario": QtdPrimario,
            "QtdIndicativo": QtdIndicativo,
            "QtdTotal": QtdTotal,
            "QtdOutlier": QtdOutlier,
            "IDCurvaSpread": IDCurvaSpread,
            "IDCurvaSubsetor": IDCurvaSubsetor,
            "DU": DU,
            "PremioDevedor": PremioDevedor,
            "IndiceLiquidezDevedor": IndiceLiquidezDevedor
        }

        props.loading(true)
        return dataLakeService.updateTableEndpoint(props.endpoint, ID, body).then(() => {
            props.loading(false)
        }).catch((err) => {
            props.loading(false)
            console.error("Error updating row:", err)
        })
    }

    const deleteRow = async (row) => {
        props.loading(true)
        const { ID } = row
        return dataLakeService.deleteTableEndpoint(props.endpoint, ID).then(() => {
            props.loading(false)
            const listRemoved = data.filter((item) => item.ID !== ID)
            setData(listRemoved)
        }).catch((err) => {
            props.loading(false)
            console.error("Error deleting row:", err)
        })
    }

    const onFilter = async (filterList, limit, offset) => {
        const query = filterList.map(obj => `${encodeURIComponent(obj.field)}=${encodeURIComponent(obj.value)}`).join('&')

        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint, query, limit, offset).then((response) => {
            props.loading(false)
            if (response && response.body) {
                const { body } = response
                const { results, count, next, previous } = body
                const list = results.map((obj) => ({ ...obj, id: obj.ID }))
                setPagination({ count: count, next: next, previous: previous })
                setData(list)
            } else {
                console.error("Invalid response structure:", response)
            }
        }).catch((err) => {
            props.loading(false)
            console.error("Error filtering data:", err)
        })
    }

    const changePage = async (endpoint) => {
        props.loading(true)
        dataLakeService.paginate(endpoint).then((response) => {
            props.loading(false)
            if (response && response.body) {
                const { body } = response
                const { results, count, previous, next } = body
                const list = results.map((obj) => ({ ...obj, id: obj.ID }))

                setPagination({ count: count, next: next, previous: previous })
                setData(list)
            } else {
                console.error("Invalid response structure:", response)
            }
        }).catch((err) => {
            props.loading(false)
            console.error("Error changing page:", err)
        })
    }

    const columns = [
        {
        field: 'ID',
            headerName: '#',
            disableColumnMenu: true,
            disableFilter: true,
            flex: true,
            minWidth: 80,
        },
        {
            field: 'CPFCNPJ',
            headerName: 'CPFCNPJ',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DataReferencia',
            headerName: 'DataReferencia',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'GrupoEconomico',
            headerName: 'GrupoEconomico',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'ScoreRating',
            headerName: 'ScoreRating',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Rating',
            headerName: 'Rating',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'QtdSecundario',
            headerName: 'QtdSecundario',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'QtdPrimario',
            headerName: 'QtdPrimario',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'QtdIndicativo',
            headerName: 'QtdIndicativo',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'QtdTotal',
            headerName: 'QtdTotal',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'QtdOutlier',
            headerName: 'QtdOutlier',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'IDCurvaSpread',
            headerName: 'IDCurvaSpread',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'IDCurvaSubsetor',
            headerName: 'IDCurvaSubsetor',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DU',
            headerName: 'DU',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'PremioDevedor',
            headerName: 'PremioDevedor',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'IndiceLiquidezDevedor',
            headerName: 'IndiceLiquidezDevedor',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CriadoEm',
            headerName: 'Data de Criação',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        },
        {
            field: 'AtualizadoEm',
            headerName: 'Data de Atualização',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        }

    ]

    return (
        <div>
            <ParameterPaginationTable
                columns={columns}
                data={data}
                setData={setData}
                createRow={createRow}
                deleteRow={deleteRow}
                updateRow={updateRow}
                filterFunction={onFilter}
                pagination={pagination}
                onChangePage={changePage}
                customFilter={true} />
        </div>
    )
}

ParameterDatalakeSpreadsDevedorTable.propTypes = {
    loading: PropTypes.func,
    endpoint: PropTypes.string,
}

export default ParameterDatalakeSpreadsDevedorTable