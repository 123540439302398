import React, { useEffect } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { useState } from 'react'
import PropTypes from 'prop-types'

const DateTimePickerSelectDate = (props) => {
    const [date, setDate] = useState(dayjs())

    useEffect(() => {
        if (props.value) {
            console.log(date)
            setDate(props.value)
        }
    }, [props.value])

    const changeDate = (value) => {
        setDate(value)
        props.onChange(value)
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']}>
                <DateTimePicker onChange={changeDate} value={date} />
            </DemoContainer>
        </LocalizationProvider>
    )
}

DateTimePickerSelectDate.propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.string
}

export default DateTimePickerSelectDate