import React from "react"
import TableColumns from "src/components/table/table-columns"
import PropTypes from 'prop-types'
import dayjs from "dayjs"
import { DATE_FORMAT_SHOW } from "src/utils/constants"
import { formatStringToFloatDecimalCase } from "src/utils/format-decimal"

const TableParametrosCalculoPreco = (props) => {

    const getDate = (value) => {
        if (props.data == null || props.data[value] == null) {
            return null
        }

        return dayjs(props.data[value]).format(DATE_FORMAT_SHOW)
    }

    const getValueFormated = (value) => {
        if (props.data == null || props.data[value] == null) {
            return null
        }

        return formatStringToFloatDecimalCase(props.data[value])
    }


    const getValue = (value) => {
        if (props.data == null) {
            return null
        }

        return props.data[value]
    }

    const columns = [
        { keyA: "Início Rentabilidade", valueA: getValue('CETIP'), keyB: "ISIN", valueB: getValue('ISIN') },
        { keyA: "Fim Renatabilidade", valueA: getValue('Serie'), keyB: "Emissão", valueB: getValue('Emissao') },
        { keyA: "Valor Nominal", valueA: getValue('TipoAtivo'), keyB: "Fluxo Base", valueB: getValue('FluxoBase') },
        { keyA: "Defasagem", valueA: getDate('DataEmissao'), keyB: "Data Vencimento", valueB: getDate('DataVencimento') },
        { keyA: "Inflação Anual", valueA: getDate('DataPrimeiraIntegralizacao'), keyB: "Data Resgate Antecipado", valueB: getDate('DataResgateAntecipado') },
        { keyA: "Mês Aniversário", valueA: getValueFormated('PU_Emissao'), keyB: "Quantidade Emitida", valueB: getValue('QuantidadeEmitida') },
        { keyA: "Projeção Inflação", valueA: getValueFormated('VolumeFinanceiro'), keyB: "Lei 12.431", valueB: getValue('Lei12431') },
        { keyA: "Inflação Negativa", valueA: getValue('Indicador'), keyB: "Taxa Accrual", valueB: getValueFormated('TaxaAccrual') },
    ]

    return (
        <>
            <TableColumns columns={columns} title={"Parâmetros para Cálculo de Preços"} />
        </>
    )
}

TableParametrosCalculoPreco.propTypes = {
    data: PropTypes.object
}


export default TableParametrosCalculoPreco