import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import ParameterTable from "src/components/table/parameter-table"
import DatalakeService from "src/services/datalake-service"
import { DATE_FORMAT_SHOW } from "src/utils/constants"
import dayjs from "dayjs"

const ParameterDatalakeContraparteTable = (props) => {
    const calcService = new DatalakeService()
    const [data, setData] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        props.loading(true)
        calcService.loadEndpoint(props.endpoint).then((response) => {
            props.loading(false)
            const { body } = response
            const { results } = body
            const list = results.map((obj, index) => ({ ...obj, id: index }))

            setData(list)
        })
    }

    const createRow = async () => {
        const body = {
            "CPFCNPJ": null,
            "RaizCNPJ": null,
            "CodigoCVM": null,
            "SituacaoCadastral": null,
            "Classificacao": null,
            "RazaoSocial": null,
            "NomeFantasia": null,
            "Setor": null,
            "SubSetor": null,
            "Site_RI": null,
            "GrupoEconomico_Nome": null,
            "GrupoEconomico_RaizCNPJ": null,
            "Observacao": null,
            "GrupoRating": null,
            "GrupoAuditoria": null,
            "GrupoCustodiante": null,
            "GrupoCoordenadorLider": null,
            "GrupoFiduciario": null,
            "GrupoIFRecebedora": null,
            "GrupoSecuritizador": null
        }

        props.loading(true)
        return calcService.createTableEndpoint(props.endpoint, body).then(() => {
            props.loading(false)
            loadData()
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const updateRow = async (newRow) => {
        const { ID, CPFCNPJ, RaizCNPJ, CodigoCVM, SituacaoCadastral, Classificacao,
            RazaoSocial, NomeFantasia, Setor, SubSetor, Site_RI, GrupoEconomico_Nome,
            GrupoEconomico_RaizCNPJ, Observacao, GrupoRating, GrupoAuditoria, GrupoCustodiante, 
            GrupoCoordenadorLider, GrupoFiduciario, GrupoIFRecebedora, GrupoSecuritizador
        } = newRow

        const body = {
            "CPFCNPJ": CPFCNPJ,
            "RaizCNPJ": RaizCNPJ,
            "CodigoCVM": CodigoCVM,
            "SituacaoCadastral": SituacaoCadastral,
            "Classificacao": Classificacao,
            "RazaoSocial": RazaoSocial,
            "NomeFantasia": NomeFantasia,
            "Setor": Setor,
            "SubSetor": SubSetor,
            "Site_RI": Site_RI,
            "GrupoEconomico_Nome": GrupoEconomico_Nome,
            "GrupoEconomico_RaizCNPJ": GrupoEconomico_RaizCNPJ,
            "Observacao": Observacao,
            "GrupoRating": GrupoRating,
            "GrupoAuditoria": GrupoAuditoria,
            "GrupoCustodiante": GrupoCustodiante,
            "GrupoCoordenadorLider": GrupoCoordenadorLider,
            "GrupoFiduciario": GrupoFiduciario,
            "GrupoIFRecebedora": GrupoIFRecebedora,
            "GrupoSecuritizador": GrupoSecuritizador
        }

        props.loading(true)
        return calcService.updateTableEndpoint(props.endpoint, ID, body).then(() => {
            props.loading(false)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const deleteRow = async (row) => {
        const { ID } = row
        return calcService.deleteTableEndpoint(props.endpoint, ID).then(() => {
            props.loading(false)
            loadData()
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const columns = [
        {
            field: 'ID',
            headerName: '#',
            flex: true,
        },
        {
            field: 'CPFCNPJ',
            headerName: 'CPFCNPJ',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'RaizCNPJ',
            headerName: 'RaizCNPJ',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'CodigoCVM',
            headerName: 'CodigoCVM',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'SituacaoCadastral',
            headerName: 'SituacaoCadastral',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'Classificacao',
            headerName: 'Classificacao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'RazaoSocial',
            headerName: 'RazaoSocial',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'NomeFantasia',
            headerName: 'NomeFantasia',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'Setor',
            headerName: 'Setor',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'SubSetor',
            headerName: 'SubSetor',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'Site_RI',
            headerName: 'Site_RI',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'GrupoEconomico_Nome',
            headerName: 'GrupoEconomico_Nome',
            flex: 1,
            minWidth: 200,
            maxWidth: 400,
            editable: true
        },
        {
            field: 'GrupoEconomico_RaizCNPJ',
            headerName: 'GrupoEconomico_RaizCNPJ',
            flex: 1,
            minWidth: 200,
            maxWidth: 400,
            editable: true
        },
        {
            field: 'Observacao',
            headerName: 'Observacao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            editable: true
        },
        {
            field: 'GrupoRating',
            headerName: 'GrupoRating',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            editable: true
        },
        {
            field: 'GrupoAuditoria',
            headerName: 'GrupoAuditoria',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            editable: true
        },
        {
            field: 'GrupoCustodiante',
            headerName: 'GrupoCustodiante',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            editable: true
        },
        {
            field: 'GrupoCoordenadorLider',
            headerName: 'GrupoCoordenadorLider',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            editable: true
        },
        {
            field: 'GrupoFiduciario',
            headerName: 'GrupoFiduciario',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            editable: true
        },
        {
            field: 'GrupoIFRecebedora',
            headerName: 'GrupoIFRecebedora',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            editable: true
        },
        {
            field: 'GrupoSecuritizador',
            headerName: 'GrupoSecuritizador',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            editable: true
        },
        {
            field: 'CriadoEm',
            headerName: 'Data de Criação',
            flex: true,
            minWidth: 150,
            type: 'date',
            valueGetter: ({ value }) => value && dayjs(value).toDate(),
            valueFormatter: params => dayjs(params.value).format(DATE_FORMAT_SHOW),
        },
        {
            field: 'AtualizadoEm',
            headerName: 'Data de Atualização',
            flex: true,
            minWidth: 150,
            type: 'date',
            valueGetter: ({ value }) => value && dayjs(value).toDate(),
            valueFormatter: params => dayjs(params.value).format(DATE_FORMAT_SHOW),
        }
    ]

    return (
        <div>
            <ParameterTable
                columns={columns}
                data={data}
                setData={setData}
                createRow={createRow}
                deleteRow={deleteRow}
                updateRow={updateRow}
                loadData={loadData} />
        </div>
    )
}

ParameterDatalakeContraparteTable.propTypes = {
    loading: PropTypes.func,
    endpoint: PropTypes.string,
}

export default ParameterDatalakeContraparteTable
