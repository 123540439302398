import React from 'react'
import { FormControl, Grid, MenuItem, Paper, Select } from '@mui/material'
import theme from 'src/utils/theme'
import PropTypes from 'prop-types'

const TableColumns = (props) => {

    const selectStyle = {
        border: 'none',
        color: '#fff',
        '& .MuiSelect-icon': {
            color: '#fff', // cor da seta do Select
        },
        '&:hover': {
            borderBottom: 'none', // remove a borda ao passar o mouse
        },
        '&:focus': {
            borderBottom: 'none', // remove a borda ao focar
        },
        '& .MuiSelect-root': {
            color: 'white', // cor do texto dentro do Select
        },
    }

    const changeSelect = (event) => {
        if (props.onChangeSelect != null) {
            props.onChangeSelect(event.target.value)
        }
    }

    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item xs={12}>
                <Paper>
                    <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ backgroundColor: theme.palette.primary.main, p: 1, color: "#fff", fontWeight: "bold" }}>
                        <Grid item textAlign="center">
                            {props.title}
                        </Grid>
                        {props.selectValueTitle != null && props.selectValueTitle.length > 0 ?
                            <Grid item textAlign="center">
                                <FormControl fullWidth>
                                    <Select
                                        displayEmpty
                                        id="select"
                                        inputProps={{
                                            'aria-label': 'Without label',
                                        }}
                                        sx={selectStyle}
                                        value={props.changeValue}
                                        onChange={changeSelect} // aqui você deve passar a função para tratar a mudança
                                    >
                                        {props.selectValueTitle.map((select, index) => <MenuItem key={index} value={select.value}><b>{select.description}</b></MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid> : null}
                    </Grid>
                    {props.columns.map((column, index) => (
                        <Grid container direction="row" justifyContent="center" alignItems="stretch" key={index} sx={{ minHeight: '56px' }}>
                            <Grid item xs={12} sm={6} md={3} textAlign="center" sx={{ border: '0.1px solid #747474', p: 2, fontWeight: "bold", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {column.keyA}
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} textAlign="center" sx={{ border: '0.1px solid #747474', p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {column.valueA}
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} textAlign="center" sx={{ border: '0.1px solid #747474', p: 2, fontWeight: "bold", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {column.keyB}
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} textAlign="center" sx={{ border: '0.1px solid #747474', p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {column.valueB}
                            </Grid>
                        </Grid>
                    ))}
                </Paper>
            </Grid>
        </Grid>
    )
}

TableColumns.propTypes = {
    columns: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    selectValueTitle: PropTypes.array,
    onChangeSelect: PropTypes.func,
    changeValue: PropTypes.number
}

export default TableColumns
